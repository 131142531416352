import { AxiosInstance } from "axios";
import axios from 'axios';
import ClientAPI, { DEFAULT_CLIENT_SUCCESS_HANDLER } from "./ClientAPI";

export default class VendorService {
    private readonly client: AxiosInstance;
    constructor(serviceUrl: string) {
        this.client = axios.create({
            baseURL: serviceUrl,
            withCredentials: false,
        })
    }

    async searchVendors(selection: string): Promise<any> {
        const params = {
            selection
        }
        return ClientAPI.Get(`vendors`, params, DEFAULT_CLIENT_SUCCESS_HANDLER, () => ClientAPI.displayErrorNotification(`Error searching for vendor: ${selection}`))
    }

    async getVendors(ids?: string, rangeStart?: number, rangeEnd?: number): Promise<any> {
        const params = {
            ids: ids ? ids : '',
            rangeStart: rangeStart ? rangeStart : 1,
            rangeEnd: rangeEnd ? rangeEnd : 10
        }
        return ClientAPI.Get(`vendors`, params, DEFAULT_CLIENT_SUCCESS_HANDLER, () => ClientAPI.displayErrorNotification("Error getting vendors"))
    }
}