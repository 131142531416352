
  import { defineComponent } from "vue";
  import { mapGetters } from "vuex";

  // Components
  import AutoComplete from 'primevue/autocomplete';

  // Services
  import VendorService from "@/services/VendorService";
  const vendorService = new VendorService(process.env.VUE_APP_ABSTRACTION_API);

  // Types
  interface Data {
    query: string,
    suggestions: string[],
  }
  
  export default defineComponent({
    name: "VendorAutocomplete",
    components: {
      AutoComplete,
    },
    emits: [
      "item-select",
      "id-select"
    ],
    props: {
      selectedId: {
        type: String,
        required:false,
        default: "",
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data(): Data {
      return {
        query: "",
        suggestions: [],
      }
    },
    watch: {
      selectedId() {
        this.getVendorById();
      },
    },
    computed: {
      ...mapGetters({
        getClient: "session/getClient",
      }),
    },
    methods: {
      handleComplete() {
        vendorService.searchVendors(this.query)
        .then((response: any) => {
          this.suggestions = response.vendor_items.map((vendor: any) => ({ vendor_id: vendor.vendor_id, name: vendor.name}));
        });
      },
      handleItemSelect(event: any) {
        this.$emit("item-select", event.value);
      },
      getVendorById() {
        if (this.selectedId && typeof this.selectedId === "string") {
          vendorService.searchVendors(this.selectedId)
          .then((response: any) => {
            if (response.vendor_items.length) {
              const vendor = response.vendor_items[0];
              this.query = vendor.vendor_id;
              this.$emit("id-select", vendor);
            }
          });
        }
      },
    },
    mounted() {
      this.getVendorById();
    }
  });
  